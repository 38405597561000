class Meet {
    constructor() {
        this.block = document.querySelectorAll('.block-meet');
        this.block.forEach( block => this.init( block ) );
    }

    init( block ) {
        this.links  = block.querySelectorAll( '[data-links]' );
        this.videos = block.querySelectorAll( '[data-videos]' );
        this.select = block.querySelector( 'select' );

        this.links.forEach( link => {
            link.addEventListener( 'click', () => {
                let index = link.getAttribute( 'data-index' );

                this.updateLinks( index );
                this.updateVideo( index );
            });
        });

        this.select.addEventListener( 'change', () => {
            let selected = this.select.options[this.select.selectedIndex];
            let index    = selected.getAttribute( 'data-index' );

            this.updateLinks( index );
            this.updateVideo( index );
        });
    }

    updateLinks( index ) {
        this.links.forEach( link => {
            link.classList.remove( 'active' );

            if ( link.getAttribute( 'data-index' ) === index ) {
                link.classList.add( 'active' );
            }
        });
    }

    updateVideo( index ) {
        this.videos.forEach( video => {
            video.classList.remove( 'active' );

            if ( video.getAttribute( 'data-index' ) === index ) {
                video.classList.add( 'active' );
            }
        });
    }
}

new Meet();